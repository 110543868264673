import { createAction, props } from '@ngrx/store';
import { AgentCompany } from 'src/app/common-models/agent-company';
// import { AgentCompany } from 'src/app/common-models/agent-company';
import {
  SignedAndCompletedViewingPass,
  ViewingPass,
  ViewingPassCreation,
  ViewingPassSignatureCreation,
} from '../viewing-pass.model';

export const LoadViewingPasses = createAction(
  '[ViewingPass] Load Viewing Passes',
  props<{ objectId: string }>(),
);
export const LoadViewingPassesByCustomer = createAction(
  '[ViewingPass] Load Viewing Passes By Customer',
  props<{ customerId: string }>(),
);
export const ViewingPassesLoaded = createAction(
  '[ViewingPass] Viewing Passes Loaded',
  props<{ payload: ViewingPass[] }>(),
);
export const ViewingPassesLoadingFailed = createAction('[ViewingPass] Viewing Passes Loading Failed');


export const LoadViewingPassDetails = createAction(
  '[ViewingPass] Load Viewing Pass Details',
  props<{ objectId: string; viewingPassId: string }>(),
);
export const ViewingPassDetailsLoaded = createAction(
  '[ViewingPass] Viewing Pass Details Loaded',
  props<{ payload: ViewingPass }>(),
);
export const ViewingPassDetailsLoadingFailed = createAction(
  '[ViewingPass] Viewing Pass Details Loading Failed'
);

export const SaveViewingPass = createAction(
  '[ViewingPass] Save Viewing Pass',
  props<{ viewingPass: ViewingPassCreation; isFinal?: boolean; shouldPrint?: boolean }>(),
);
export const SaveViewingPassSucceded = createAction(
  '[ViewingPass] Succeeded To Create And Save Viewing Pass',
  props<{ showToast: boolean }>(),
);
export const SaveViewingPassFailed = createAction(
  '[ViewingPass] Failed To Create And Save Viewing Pass',
  props<{ showToast: boolean }>(),
);

export const CreateAndUploadViewingPassDocumentFailed =
  createAction('[ViewingPass] Failed To Create And Upload Viewing Pass Document');

export const SignAndCompleteViewingPass = createAction(
  '[ViewingPass] Sign And Complete Viewing Pass',
  props<{ viewingPassSignature: ViewingPassSignatureCreation }>()
);
export const SignAndCompleteViewingPassSuccess = createAction(
  '[ViewingPass] Successfully signed and completed ViewingPass',
  props<{ signedAndCompletedViewingPass: SignedAndCompletedViewingPass }>()
);
export const SendViewingPassEmail = createAction(
  '[Viewing Pass] Send Viewing Pass Email',
  props<{
    viewingPassId: string;
    emailData: any;
    agentCompany: AgentCompany;
  }>(),
);

export const SendCustomerPortalLink = createAction(
  '[Viewing Pass] Send Customer Portal Link',
  props<{ viewingPassId: string; emailData: any }>(),
);

export const DeleteViewingPass = createAction(
  '[ViewingPass] Delete Viewing Pass',
  props<{ viewingPassId: string }>()
);

export const DeleteViewingPassSucceded = createAction(
  '[ViewingPass] Viewing Pass Deleted',
  props<{ viewingPassId: string }>()
);

export const DeleteViewingPassFailed = createAction('[ViewingPass] Viewing Pass Delete Failed', props<{ viewingPassId: string }>());
