import { ActionsSubject, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { digitalOfferingSelectors, RentalOfferFeatureState } from './store';
import { RentalOfferActions } from './store/action-types';
import { RentalOfferApiFacade } from './rental-offer-api.facade';
import { Observable } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { RentalOffer, RentalOfferSignatureCreation } from './rental-offer.model';
import { getAgentCompany } from 'src/app/common-models/mapping';
import { AuthService } from 'services/auth.service';
import { DocumentService } from 'services/document.service';
import { CustomerWithContact } from '@domains/customer';
import { ofType } from '@ngrx/effects';
import { EmailPreviewBodyDto } from '@types';

@Injectable({ providedIn: 'root' })
export class RentalOfferDataFacade {
  rentalOffers$ = this._store$.select(digitalOfferingSelectors.rentalOffers);
  rentalOffersLoading$ = this._store$.select(digitalOfferingSelectors.rentalOffersLoading);
  rentalOfferDetails$ = this._store$.select(digitalOfferingSelectors.rentalOfferDetails);
  rentalOfferDetailsLoading$ = this._store$.select(digitalOfferingSelectors.rentalOfferDetailsLoading);

  constructor(
    private readonly _store$: Store<RentalOfferFeatureState>,
    private readonly _authService: AuthService,
    private readonly _docService: DocumentService,
    private readonly _rentalOfferApiFacade: RentalOfferApiFacade,
    private readonly actionsListener$: ActionsSubject,
  ) { }

  public loadRentalOffers(objectId: string): void {
    this._store$.dispatch(RentalOfferActions.LoadRentalOffers({ objectId }));
  }

  public loadRentalOffersByCustomer(customerId: string): void {
    this._store$.dispatch(RentalOfferActions.LoadRentalOffersByCustomer({ customerId }));
  }

  public loadRentalOfferDetails(objectId: string, rentalOfferId: string): void {
    this._store$.dispatch(RentalOfferActions.LoadRentalOfferDetails({ objectId, rentalOfferId }));
  }

  public sendTenantCustomerPortalNotification(objectId: string, rentalOfferId: string): void {
    this._store$.dispatch(RentalOfferActions.SendTenantCustomerPortalNotification({ objectId, rentalOfferId }));
  }

  public saveRentalOffer(rentalOffer: any, isFinal = false): void {
    this._store$.dispatch(RentalOfferActions.SaveRentalOffer({ rentalOffer, isFinal }));
  }

  public sendRentalOfferEmail(rentalOfferId: string, emailData: any): void {
    const agentCompany = getAgentCompany(this._authService);
    this._store$.dispatch(RentalOfferActions.SendRentalOfferEmail({
      rentalOfferId,
      emailData,
      agentCompany,
    }));
  }

  public sendCustomerPortalLinkEmail(rentalOfferId: string, emailData: any): void {
    this._store$.dispatch(RentalOfferActions.SendCustomerPortalLink({ rentalOfferId, emailData }));
  }

  public sendLandlordCustomerPortalLinkEmail(rentalOfferId: string, emailData: any): void {
    this._store$.dispatch(RentalOfferActions.SendLandlordCustomerPortalLink({ rentalOfferId, emailData }));
  }

  public failedCreateAndUploadRentalOfferDocument(): void {
    this._store$.dispatch(RentalOfferActions.CreateAndUploadRentalOfferDocumentFailed());
  }

  public deleteRentalOffer(rentalOfferId: string, objectId: string): void {
    this._store$.dispatch(RentalOfferActions.DeleteRentalOffer({ rentalOfferId, objectId }));
  }

  public updateRentalOfferMasterTemplate(rentalOfferId: string, objectId: string): void {
    this._store$.dispatch(RentalOfferActions.UpdateRentalOfferMasterTemplate({ rentalOfferId, objectId }));
  }

  public resetRentalOffer(objectId: string, rentalOfferId: string): void {
    this._store$.dispatch(RentalOfferActions.ResetRentalOffer({ objectId, rentalOfferId }));
  }

  public signAndCompleteRentalOffer(rentalOfferSignature: RentalOfferSignatureCreation): void {
    this._store$.dispatch(RentalOfferActions.SignAndCompleteRentalOffer({ rentalOfferSignature }));
  }

  public draftSignRentalOffer(rentalOfferSignature: RentalOfferSignatureCreation, openEmailDialog = true): void {
    this._store$.dispatch(RentalOfferActions.DraftSignRentalOffer({ rentalOfferSignature, openEmailDialog }));
  }

  public updateRentalOfferCustomers(
    tenants: CustomerWithContact[],
    landlords: CustomerWithContact[],
    rentalOfferId: string,
    objectId: string
  ): void {
    this._store$.dispatch(RentalOfferActions.UpdateRentalOfferCustomers({
      tenants,
      landlords,
      objectId,
      rentalOfferId,
    }));
  }

  public createRentalOffer$(rentalOffer: any): Observable<RentalOffer> {
    return this._rentalOfferApiFacade.saveRentalOffer$(rentalOffer);
  }

  public getRentalOfferDetails$(objectId: string, rentalOfferId: string): Observable<RentalOffer> {
    return this._rentalOfferApiFacade.loadRentalOfferDetails$(objectId, rentalOfferId);
  }

  public updateOwnerAvailability$(objectId: string, data: any): Observable<any> {
    return this._rentalOfferApiFacade.updateRentalOffersAvailability$(objectId, data);
  }

  public getRentalOfferMasterTemplate$(objectId: string): Observable<RentalOffer | null> {
    return this._rentalOfferApiFacade.getRentalOfferMasterTemplate$(objectId);
  }

  public getRentalOfferEmailVariables$(rentalOfferId: string, documentUrl: string): Observable<any> {
    return this._rentalOfferApiFacade.getRentalOfferEmailVariables$(rentalOfferId, documentUrl);
  }

  public getRentalOfferCustomerPortalEmailVariables$(rentalOfferId: string, handInOffer: boolean): Observable<any> {
    return this._rentalOfferApiFacade.getRentalOfferCustomerPortalLinkEmailVariables$(rentalOfferId, handInOffer);
  }

  public getRentalOfferOwnerPortalLinkEmailVariables$(rentalOfferId: string): Observable<any> {
    return this._rentalOfferApiFacade.getRentalOfferOwnerPortalLinkEmailVariables$(rentalOfferId);
  }

  public createRentalOfferDocument$(objectId: string, rentalOfferId: string): Observable<string> {
    const agentCompany = getAgentCompany(this._authService);
    return this._rentalOfferApiFacade.createRentalOfferDocument$(objectId, rentalOfferId, agentCompany);
  }

  public compressFiles$(objectId: string, rentalOfferId: string, filePaths: string[], fileNames: string[]): Observable<string> {
    return this._rentalOfferApiFacade.compressFiles$(objectId, rentalOfferId, filePaths, fileNames);
  }

  public showRentalOfferDocument(objectId: string, rentalOfferId: string): void {
    this.createRentalOfferDocument$(objectId, rentalOfferId).pipe(
      first(),
      tap((url) => {
        this._docService.openDocumentLink(url);
      }),
      catchError((err) => {
        this.failedCreateAndUploadRentalOfferDocument();
        throw err;
      })
    ).subscribe();
  }

  public rentalOfferSaved$() {
    return this.actionsListener$.pipe(
      ofType(RentalOfferActions.SaveRentalOfferSuccess)
    );
  }
}
