import { Injectable, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PROPERTYTYPE } from "@domains/rental-offer";
import { Subscription } from 'rxjs';
import { BuyingOffer, CONTRACTPARTICIPANTS, SELLERACCEPTANCE } from "./buying-offer.model";
import { Object, RealtyPriceItem } from '@domains/object';
import { BusinessCustomerFormService } from "@domains/business-customer";
import { CustomerType } from "src/app/common-components/customer-type-selector/model";
import { CustomerFormService, CustomerWithContact } from "@domains/customer";

@Injectable({ providedIn: 'root' })
export class BuyingOfferFormService implements OnDestroy {
  phoneValidator = Validators.pattern('[- +()0-9]+');

  private _subscription = new Subscription

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _customerFormService: CustomerFormService,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public convertCustomerToFormGroup(c: CustomerWithContact) {
    return this._customerFormService.convertToFormGroup(c);
  }

  public createPropertyForm(object?: Object): FormGroup {
    const currentYear = new Date().getFullYear();

    const constructionYear = object?.additionalInformation?.constructionYear ?? 0;
    const constructionYearCa = object?.additionalInformation?.constructionYearCa ?? false;

    return this._fb.group({
      objectType: '',
      address: '',
      constructionYear: [constructionYear, [Validators.max(currentYear)]],
      constructionYearCa: [constructionYearCa],
      registrationLand: '', //TODO check if we get from Edi
      cadastralCommunity: object?.additionalInformation?.cadastralCommunity ?? '',
      entryNumber: object?.additionalInformation?.entryNumber ?? '',
      parcelNumber: object?.additionalInformation?.parcelNumber ?? '',
      parcelNumbers: this._fb.array((object?.additionalInformation?.parcelNumbers ?? [])),
      propertyType: PROPERTYTYPE.ALLEINEIGENTUM,
      propertyTypeNotes: '',
      shareInOwnership: '',
      cadastreLabel: object?.cadastreLabel ?? '',
      parkingSlotsLabel: this._fb.array((object?.parkingSlotsLabel ?? [])),
      complementLabel: this._fb.array(object?.complementLabel ?? []),
    });
  }

  public createPropertyDetailsForm(object?: Object): FormGroup {
    return this._fb.group({
      property: this.createPropertyForm(object),
      housingSubsidies: false,
      rulesOfUse: false,
      price: object?.price ?? 0,
      includingAllTaxes: false,
    });
  }

  public createPaymentForm(): FormGroup {
    return this._fb.group({
      repayment: false,
      repayments: this._fb.array([]),
      repaimentObligation: 0,
      repaimentHolder: '',
      repaimentAmount: 0,
      automatedCalculation: true,
      additionalConditions: false,
      additionalConditionsDetails: ''
    });
  }

  public createRepaymentsArrayItemForm(): FormGroup {
    return this._fb.group({
      cadastreNumber: '',
      takeover: false,
      obligationType: '',
      obligationTypeOther: '',
      pawnee: '',
      pawneeIBAN: '',
      repaimentObligation: 0,
      repaimentHolder: '',
    });
  }

  public createDrafterOfDeedsForm(): FormGroup {
    return this._fb.group({
      contractorId: null,
      contractPartnerId: null,
      autoSendContractPartnerEmail: false,
      name: '',
      address: '',
      phone: ['', this.phoneValidator],
      email: '',
      assignment: false,
      additionalCosts: false,
      additionalConditions: false,
      additionalConditionsDetails: '',
      contractPrice: 0,
    })
  }

  public createHandoverAndConditionForm(): FormGroup {
    return this._fb.group({
      handoverOnSigning: false,
      handoverOnMoneyReceived: true,
      handoverOnOther: '',
      handoverDate: null,
      handoverConditionDate: new Date().toISOString().substring(0, 10),
      handoverNotes: '',
      warranty: false,
      warrantyException: false,
      warrantyExceptionDetails: '',
      warrantyExclusion: false,
      needOfRedevelopment: false,
      removeCondition: false,
      removeConditionDetails: '',
      removeResponsibility: CONTRACTPARTICIPANTS.SELLER,
      costResponsibility: CONTRACTPARTICIPANTS.BUYER,
      leaveCondition: false,
      leaveConditionDetails: '',
      promiseCondition: false,
      promiseConditionDetails: '',
    })
  }

  public createAdditionalConditionsForm(): FormGroup {
    return this._fb.group({
      additionalConditionsDetails: '',
      expenseAllowanceAmount: 5000,
      commissionBase: 4,
    })
  }

  public createContractTermsForm(): FormGroup {
    return this._fb.group({
      contractValidUntil: new Date().toISOString().substring(0, 10),
      alternativeValidity: '',
      restriction: false,
      restrictionDetails: '',
      financeAgreement: false,
      financeRestriction: false,
      financeRestrictionDays: 0,
    })
  }

  public createBuyersArrayItemForm(): FormGroup {
    return this._fb.group({
      customer: this._customerFormService.createCustomerFormGroup(true),
      buyer: this.createBuyerDetailsForm(),
    });
  }

  public createSellersArrayItemForm(): FormGroup {
    return this._fb.group({
      customer: this._customerFormService.createCustomerFormGroup(true),
      seller: this.createSellerDetailsForm(),
    });
  }

  public createBuyerDetailsForm(): FormGroup {
    return this._fb.group({
      customerId: '',
      nationalInsuranceNumber: 0,
      shares: this._fb.array([]),
    })
  }

  public createSharesArrayItemForm(): FormGroup {
    return this._fb.group({
      stake: '',
      bLnr: '',
    });
  }

  public createBuyersTrusteeshipForm(): FormGroup {
    return this._fb.group({
      trusteeship: true,
      trusteeshipOwner: '',
    })
  }

  public createSellerDetailsForm(): FormGroup {
    return this._fb.group({
      customerId: '',
      shares: this._fb.array([]),
    })
  }

  public createDocumentsArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: '',
      title: '',
      documentFrom: new Date().toISOString().substring(0, 10),
      state: ''
    });
  }

  public createSellersAcceptanceForm(): FormGroup {
    return this._fb.group({
      sellerAcceptance: SELLERACCEPTANCE.DECISSIONPENDING,
      changeAcceptanceUntil: new Date().toISOString().substring(0, 10),
      changeDetails: ''
    })
  }

  public createBuyersAcceptanceWithChangeForm(): FormGroup {
    return this._fb.group({
      buyersAcceptanceWithChange: true
    })
  }

  public createPrintSettingsForm(): FormGroup {
    return this._fb.group({
      hideSellerOnPrint: false,
  })
  }

  public createPriceDetailsItemsForm(realtyPrice?: RealtyPriceItem): FormGroup {
    return this._fb.group({
      currency: realtyPrice?.currency ?? '',
      net: realtyPrice?.net ?? 0,
      gross: realtyPrice?.gross ?? 0,
      vat_absolute: realtyPrice?.vat_absolute ?? 0,
      vat_percentage: realtyPrice?.vat_percentage ?? 0,
    });
  }

  public createPriceDetailsForm(object?: Object): FormGroup {
    return this._fb.group({
      purchase_price: this.createPriceDetailsItemsForm(object?.realtyPrice?.purchase_price),
      parking: this.createPriceDetailsItemsForm(object?.realtyPrice?.parking),
      garage: this.createPriceDetailsItemsForm(object?.realtyPrice?.garage),
      operating: this.createPriceDetailsItemsForm(object?.realtyPrice?.operating),
      heating: this.createPriceDetailsItemsForm(object?.realtyPrice?.heating),
      hot_water: this.createPriceDetailsItemsForm(object?.realtyPrice?.hot_water),
      cooling: this.createPriceDetailsItemsForm(object?.realtyPrice?.cooling),
      maintenance_funds: this.createPriceDetailsItemsForm(object?.realtyPrice?.maintenance_funds),
      elevator: this.createPriceDetailsItemsForm(object?.realtyPrice?.elevator),
      others: this.createPriceDetailsItemsForm(object?.realtyPrice?.others),
    });
  }

  public createBuyingOfferForm(object?: Object): FormGroup {
    const form = this._fb.group({
      language: 'de',
      propertyDetails: this.createPropertyDetailsForm(object),
      realtyPrice: this.createPriceDetailsForm(object),
      paymentDetails: this.createPaymentForm(),
      drafterOfDeedsInfo: this.createDrafterOfDeedsForm(),
      handoverAndConditionBuy: this.createHandoverAndConditionForm(),
      additionalConditionsBuy: this.createAdditionalConditionsForm(),
      contractTerms: this.createContractTermsForm(),
      buyerType: [CustomerType.Private],
      buyers: this._fb.array([]),
      businessBuyer: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
        shares: this._fb.array([]),
      }),
      sellerType: [CustomerType.Private],
      sellers: this._fb.array([]),
      businessSeller: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
        shares: this._fb.array([]),
      }),
      documents: this._fb.array([]),
      sellersAcceptance: this.createSellersAcceptanceForm(),
      buyersTrusteeship: this.createBuyersTrusteeshipForm(),
      buyersAcceptanceWithChange: this.createBuyersAcceptanceWithChangeForm(),
      printSettings: this.createPrintSettingsForm(),
    });

    form.get('buyerType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        form.get('businessBuyer')?.disable();
        form.get('buyers')?.enable();
      } else if (value === CustomerType.Business) {
        form.get('businessBuyer')?.enable();
        form.get('buyers')?.disable();
      }
    });

    form.get('sellerType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        form.get('businessSeller')?.disable();
        form.get('sellers')?.enable();
      } else if (value === CustomerType.Business) {
        form.get('businessSeller')?.enable();
        form.get('sellers')?.disable();
      }
    });

    return form;
  }

  public createBuyingOfferInitialValue(object: Object): BuyingOffer {
    const buyingOfferForm = this.createBuyingOfferForm(object);
    const buyerForm = this.createBuyerDetailsForm();
    const sellerForm = this.createSellerDetailsForm();

    return {
      ...buyingOfferForm.value,
      objectId: object.id,
      state: 'pending',
      lastVisited: '',
      buyerType: CustomerType.Private,
      buyers: [buyerForm.value],
      businessBuyer: undefined,
      sellerType: CustomerType.Private,
      sellers: [sellerForm.value],
      businessSeller: undefined,
    };
  }


  public addCustomerValidators(customerGroup: FormGroup): void {
    const firstnameControl = customerGroup.get('firstname');
    const lastnameControl = customerGroup.get('lastname');
    const emailControl = customerGroup.get('contact.email');

    if (firstnameControl) {
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();
    }
    if (lastnameControl) {
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();
    }
    if (emailControl) {
      emailControl.setValidators([Validators.email, Validators.required]);
      emailControl.updateValueAndValidity();
    }

    customerGroup.markAllAsTouched();
    customerGroup.markAsDirty();
  }

}
